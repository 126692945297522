<template>
<div class="pro-company white-bg">
    <div class="all-top">
        <div class="all-top-bg">
            <img src="@/assets/img/stockbg.svg" alt="">
        </div>
        <h1 data-aos="fade-up" class="top-h1"> {{company.data[`title_${$i18n.locale}`]}} </h1>
        <p data-aos="fade-up" class="top-p"> {{company.data[`description_${$i18n.locale}`]}}</p>
    </div>
    <div class="pro-company-title">
             <h1 data-aos="fade-up"> {{company.data[`about_title_${$i18n.locale}`]}} </h1>
             <p data-aos="fade-up">{{company.data[`about_text_${$i18n.locale}`]}}  </p>
         </div>
     <div class="pro-company-linkes">
        <div class="pro-company-link" v-for="(item, i) in company.data.aboutBlock" :key="i" data-aos="fade-up">
            <div class="procompany-link-img">
                <img  :src="`https://avior.prestigeautofl.com${company.files[`aboutBlock${item.id}`]}`" alt="">
            </div>
            <div class="pro-company-link-text">
                <p>
                    {{item[`title_${$i18n.locale}`]}}
                </p>
            </div>
        </div> 
     </div>
    <div class="finance-companies mw-1920">
        <h1 data-aos="fade-up">{{company.data[`finance_title_${$i18n.locale}`]}} </h1>
        <div class="finance-companies-items">
            <div class="finance-companies-item" v-for="(item, i) in company.data.financeBlock" :key="i" data-aos="fade-up">
                <div class="finance-companies-item-card">
                <div class="finance-companies-item-img">
                    <img :src="`https://avior.prestigeautofl.com${company.files[`financeBlock${item.id}`]}`" alt="">
                </div>
                <div class="finance-companies-item-text">
                    <p>{{item[`title_${$i18n.locale}`]}}</p>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pro-projects">
        <div class="pro-project-title">
            <h1 data-aos="fade-up">{{company.data[`proect_title_${$i18n.locale}`]}}</h1>
            <p data-aos="fade-up"> {{company.data[`proect_description_${$i18n.locale}`]}}</p>
        </div>
        <div class="pro-project-items">
            <div class="pro-project-item" v-for="(item, i) in company.data.proectBlock" :key="i" data-aos="fade-up">
                <div class="pro-project-card">
                    <div class="pro-project-card-img">
                        <img :src="`https://avior.prestigeautofl.com${company.files[`proectBlock${item.id}`]}`"  alt="">
                    </div>
                    <div class="pro-project-card-text">
                        <p>{{item[`title_${$i18n.locale}`]}}</p>
                    </div>
                </div>
            </div>  
        </div>
    </div>
    <div class="pro-partners">
        <div class="pro-partners-title">
            <h1 data-aos="fade-up"> {{company.data[`partner_title_${$i18n.locale}`]}} </h1>
            <h2 data-aos="fade-up">{{company.data[`partner_descripton_${$i18n.locale}`]}} </h2>
            <p data-aos="fade-up">{{company.data[`partner_text_${$i18n.locale}`]}} </p>
        </div>
        <div class="pro-partners-items">
            <a :href="item.link" class="pro-partners-item" v-for="(item, i) in company.data.partnerBlock" :key="i" data-aos="fade-up">
                <div class="pro-partners-card">
                    <img :src="`https://avior.prestigeautofl.com${company.files[`partnerBlock${item.id}`]}`" alt="">
                </div>
            </a> 
        </div>
    </div>
    <div class="advantege-linkes white-bg" v-if="company.data.linkes">
        <SystemLinkes />
    </div>
    <div class="advantege-credit" v-if="company.data.credit">
        <Credit title="Розрахунок позики" />
    </div>
    <div class="advantage-about" v-if="company.data.contact">
        <div class="advantage-about-title">
            <h1>Напишіть нам :</h1>
        </div>
        <div class="advantage-about-item">
            <div class="advantate-about-card">
                <ContactCard />
            </div>
            <div class="advantate-about-img">
                <img src="@/assets/img/big-a.svg" alt="">
            </div>
            
        </div>
    </div> 
    
</div>
</template>

<script>
import SystemLinkes from '../components/SystemLinks.vue'
import Credit from '../components/Credit.vue'
import ContactCard from '../components/ContactCard.vue'
 export default {
     name: 'advantages',
     components: {
         SystemLinkes,
         Credit,
         ContactCard
     },
     computed:{
         company(){
             return this.$store.state.Company
         }
     },
     data() {
         return {
             input: '',
             kredit_type: 'pledge',
             a: 10,
         }
     },

 }
</script>

<style lang="scss">
.all-top {
    padding: 250px 120px 150px 120px;
    background: var(--bg-top);
    position: relative;
    overflow: hidden;

    h1 {
        font-family: Stem-Medium;
        font-size: 51px;
        line-height: 60px;
        color: var(--other-black);
        position: relative;
        z-index: 1;
    }

    p {
        font-family: Stem-Medium;
        font-size: 24px;
        line-height: 28px;
        color: var(--white-black);
        margin-top: 20px;
        position: relative;
    }

    .all-top-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        justify-content: flex-end;

    }
}
@media screen and(max-width:768px) {
   .all-top {
    padding: 150px 20px 100px 20px; 

    h1 { 
        font-size: 32px;
        line-height: 36px; 
        text-align: center;
    }
    p { 
        font-size: 1.125rem;
        line-height: 1.25rem; 
        text-align: center;
    }
    .all-top-bg {
        display: none;

    }
} 
    
}
.pro-company-title{
    padding: 80px 120px 80px 120px;
    h1{
        font-family: Stem-Medium;
        font-size: 40px;
        line-height: 42px; 
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--other-black);
    }
    p{
        font-family: Stem-Regular;
        font-size: 1.125rem;
        line-height: 27px; 
        margin-top: 20px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: var(--white-black);
    }
}
.pro-company-linkes{
    padding: 0px 120px 0px 120px;
    display: flex;
    justify-content: center;
    .pro-company-link{
        max-width: 310px;
        padding: 30px;
        .procompany-link-img{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
             
            img{
                height: 100%;
            }
        }
        .pro-company-link-text{
            width: 100%;
            padding-top: 15px;
            p{
                font-family: Stem-Regular;
                font-size: 1rem;
                line-height: 27px; 
                text-align: center;
                font-feature-settings: 'tnum' on, 'lnum' on;
                color: var(--white-black);
            }
        }
    }
}
.finance-companies{
    padding:60px 90px;
    h1{
        padding: 60px 30px 60px 30px;
        font-family: Stem-Medium;
        font-size: 40px;
        line-height: 42px;
        /* identical to box height, or 105% */

        font-feature-settings: 'tnum' on, 'lnum' on;

        color: var(--other-black);
    }
    .finance-companies-items{
        display: flex;
        align-items: center;
        justify-content: center;
        .finance-companies-item{
            padding:  30px;
        }
        .finance-companies-item-card{
            max-width: 360px; 
            width: 100%;
            display: flex;
            align-items: center;
            background: var(--bg-top);
            border-radius: 10px;
            padding:30px;
            cursor: pointer;
            transition: all 0.1s ease-in;
            &:hover{
                transform: scale(1.02);
                background: var(--white-bg);
                box-shadow: 0px 6px 16px -8px rgba(0, 72, 153, 0.12), 0px 9px 28px rgba(0, 72, 153, 0.05), 0px 12px 48px 16px rgba(0, 72, 153, 0.03);


            }
        }
        .finance-companies-item-img{ 
            width: 50px;
            height: 50px; 
            display: flex;
            align-items: center;
            img{
                width: 100%;
                max-height: 100%;
            }
        }
        .finance-companies-item-text{
            flex:1;
            padding-left: 30px;
            display: flex;
            align-items: center;
            p{
                font-family: Stem-Regular;
font-size: 1rem;
line-height: 1.125rem;
display: flex;
align-items: center;

color: var(--white-black);
            }

        }
    }

}
.pro-projects{
    padding:30px 90px;
    .pro-project-title{
        padding: 30px 30px;
        h1{
            font-family: Stem-Medium;
            font-size: 40px;
            line-height: 42px; 
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--other-black);
        }
        p{
            font-family: Stem-Medium;
            font-size: 24px;
            line-height: 1.75rem; 
            color: var(--white-black);
            margin-top: 20px;
        }

    }
    .pro-project-items{
        display: flex;
        justify-content: center;
        .pro-project-item{
            padding: 30px;
        }
        .pro-project-card{
            max-width: 360px;
            .pro-project-card-img{
                height: 200px;
                display: flex;
                align-items: center;
                border-radius: 4px;
                overflow: hidden;
                img{
                    height: 100%;
                    box-shadow: 0px 6.53254px 17.4201px -8.71006px rgba(0, 72, 153, 0.12), 0px 9.79882px 30.4852px rgba(0, 72, 153, 0.05), 0px 13.0651px 52.2604px 17.4201px rgba(0, 72, 153, 0.03);
                    max-width: 100%;
                }
            }
            .pro-project-card-text{
                padding-top: 40px;
                p{
                    font-family: Stem-Regular;
                    font-size: 1.125rem;
                    line-height: 27px;
                    /* or 150% */

                    font-feature-settings: 'tnum' on, 'lnum' on;

                    color: var(--white-black);

                }
            }
        }
    }
}
.pro-partners{
    padding: 90px;
    .pro-partners-title{
        padding: 0px 30px;
        padding-bottom: 30px;
        h1{
            font-family: Stem-Medium;
            font-size: 40px;
            line-height: 42px; 
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--other-black);
        }
        h2{
            font-family: Stem-Medium;
            font-size: 24px;
            line-height: 1.75rem; 
            color: var(--white-black);
            margin-top: 20px;
        }
        p{
            font-family: Stem-Regular;
            font-size: 1.125rem;
            line-height: 27px;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--white-black);
            margin-top: 40px;
        }
    }
    .pro-partners-items{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .pro-partners-item{
            padding: 30px;
        }
        .pro-partners-card{
            max-width: 200px;
            max-height: 50px;
            img{
                max-width: 100%;
                max-height: 100%;
            }

        }
    }
}

@media screen and(max-width:1100px) {
    .pro-company-title{
    padding: 60px 20px 60px 20px;
     
}
.pro-company-linkes{
    padding: 0px 20px 0px 20px; 
    justify-content: center;
    flex-wrap: wrap;
    .pro-company-link{
        max-width: 400px; 
        padding: 30px;
        width: 50%;
    }
}
.finance-companies{
    padding:60px 0px;
    h1{
        padding: 60px 20px 60px 20px; 
    }
    .finance-companies-items{ 
         display: block;
         .finance-companies-item{
             padding: 20px;
         }
        .finance-companies-item-card{
            max-width: 360px; 
             
        }
        
    }

}
.pro-projects{
    padding:30px 0px;
    .pro-project-title{
        padding: 20px;
    }
    .pro-project-items{
        display: block;
        .pro-project-item{
            padding: 30px 20px;
        } 
    }
}
.pro-partners{
    padding: 60px 0px;
    .pro-partners-title{
        padding: 0px 20px;
        padding-bottom: 30px;
    }
    .pro-partners-items{ 
        .pro-partners-item{
            padding: 20px;
        }
        
    }
}
}


@media screen and(max-width:768px) {
    .pro-company-title{ 
    h1{ 
        font-size: 24px;
        line-height: 1.75rem;  
    }
    p{ 
        font-size: 1rem;
        line-height: 29px;  
    }
}
.pro-company-linkes{  
    .pro-company-link{ 
         
        .pro-company-link-text{ 
            p{ 
                font-size: 0.875rem;
                line-height: 1.125rem;  
            }
        }
    }
}
.finance-companies{ 
    h1{ 
        font-size: 24px;
        line-height: 1.75rem; 
    }
    .finance-companies-items{
        
         
        
        .finance-companies-item-text{ 
            p{ 
            font-size: 0.875rem;
            line-height: 1.125rem; 
 
            }

        }
    }

}
.pro-projects{ 
    .pro-project-title{ 
        h1{ 
            font-size: 24px;
            line-height: 1.75rem;  
        }
        p{ 
            font-size: 1rem;
            line-height: 1.25rem;  
            margin-top: 15px;
        }

    }
    .pro-project-items{ 
         
        .pro-project-card{
             
            .pro-project-card-text{ 
                p{ 
                    font-size: 1rem;
                    line-height: 1.25rem; 

                }
            }
        }
    }
}
.pro-partners{ 
    .pro-partners-title{ 
        h1{ 
            font-size: 24px;
            line-height: 1.75rem;  
        }
        h2{ 
            font-size: 1rem;
            line-height: 1.25rem;  
            margin-top: 15px;
        }
        p{ 
            font-size: 1rem;
            line-height: 1.25rem;  
        }
    }
    .pro-partners-items{ 
        .pro-partners-card{
            max-width: 100px;
        }
    }
}
}
@media screen and(max-width:550px) {
.pro-company-linkes{  
    .pro-company-link{
        max-width: 400px; 
        padding: 30px 20px;
        width: 100%;
    }
}
}





.advantege-linkes{
    display: flex; 
    justify-content: center;
    background: var(--bg-top);
}
.advantege-credit{
    display: flex;
    justify-content: center;
    padding: 120px 0px;
    width: 100%;
}
.advantage-about{
    background: var(--bg-top);
    .advantage-about-title{
        padding: 50px 120px 0px 120px;
        
        h1{
            font-family: Stem-Medium;
            font-size: 40px;
            line-height: 42px; 
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--other-black);
        }
    }
    .advantage-about-item{
        display: flex;
        flex-wrap: wrap; 
        .advantate-about-card{
            width: 50%;
            padding: 50px 120px;
        }
        .advantate-about-img{
            display: flex;
            width: 50%;
            padding-top: 50px;
            justify-content: center;
            align-items: flex-end;
            img{
                max-width: 100%;
            }
        }
    }
}
@media screen and(max-width:1250px) {
    
    .advantage-about{
    background: var(--bg-top);
    .advantage-about-title{
        padding: 50px 120px 0px 120px;
        
         
    }
    .advantage-about-item{ 
        .advantate-about-card{
            width: 65%; 
        }
        .advantate-about-img{ 
            width: 35%; 
        }
    }
} 
}
 
@media screen and(max-width:768px){
   
.advantage-about{ 
    .advantage-about-title{
        padding: 50px 20px 0px 20px;
        
        h1{
            font-family: Stem-Medium;
            font-size: 32px;
            line-height: 36px; 
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: var(--other-black);
        }
    }
    .advantage-about-item{ 
        flex-wrap: wrap; 
        .advantate-about-card{
            width: 100%;
            padding: 50px 20px;
        }
        .advantate-about-img{
            display: flex;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 50px; 
            img{
                max-width: 100%;
            }
        }
    }
}
.advantege-credit{ 
    padding: 60px 0px; 
}
}
</style>
